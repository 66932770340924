html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: inherit;
  background-color: rgb(240, 240, 240);
}
